<template>
  <div class="content-wrapper">
    <div class="content-body">
      <div class="row">
        <div class="col-12">
          <div data-v-5cb60a6f="" class="card"><!----><!---->
            <div data-v-5cb60a6f="" class="m-2">
              <div data-v-5cb60a6f="" class="row">
                <div class="col-6">
                  <input
                      data-v-5cb60a6f="" type="text" placeholder="Search..." class="d-inline-block mr-1 form-control"
                      id="__BVID__1065"
                  >
                </div>
                <div class="col-3">
                  <router-link class="btn btn-primary" :to="{ name: 'apps-product-add'}"> Add Product</router-link>
                </div>
              </div>
            </div>
            <div data-v-5cb60a6f="" class="position-relative table-responsive">
              <table
                  id="__BVID__1070"
                  role="table"
                  aria-busy="false"
                  aria-colcount="7"
                  class="table b-table"
              ><!---->
                <!---->
                <thead
                    role="rowgroup"
                    class=""
                ><!---->
                <tr
                    role="row"
                    class=""
                >
                  <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="1"
                      aria-sort="descending"
                      class=""
                  >
                    <div>#</div>
                  </th><th
                    role="columnheader"
                    scope="col"
                    tabindex="0"
                    aria-colindex="3"
                    aria-sort="none"
                    class=""
                >
                  <div>Phone</div>
                </th><th
                    role="columnheader"
                    scope="col"
                    tabindex="0"
                    aria-colindex="4"
                    aria-sort="none"
                    class=""
                >
                  <div>Total Price</div>
                </th><th
                    role="columnheader"
                    scope="col"
                    tabindex="0"
                    aria-colindex="6"
                    aria-sort="none"
                    class=""
                >
                  <div>Order Date</div>
                </th>
                  <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="6"
                      aria-sort="none"
                      class=""
                  >
                    <div>Due Date</div>
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody role="rowgroup"><!---->
                <tr
                    id="__BVID__1070__row_5036"
                    role="row"
                    data-pk="5036"
                    class=""
                    v-for="(item,index) in getterListInvoice"
                >
                  <td
                      aria-colindex="1"
                      role="cell"
                      class=""
                  ><a
                      data-v-5cb60a6f=""
                      href="/apps/invoice/preview/5036"
                      class="font-weight-bold"
                      target="_self"
                  > {{ index + 1 }} </a></td>
                  <td
                      aria-colindex="3"
                      role="cell"
                      class=""
                  >
                    <div
                        data-v-5cb60a6f=""
                        class="media"
                    >
                      <div
                          data-v-5cb60a6f=""
                          class="media-body"
                      ><span
                          data-v-5cb60a6f=""
                          class="font-weight-bold d-block text-nowrap"
                      > {{ item.phone }} </span></div>
                    </div>
                  </td>
                  <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                  >
                    {{ item.total_price }}
                  </td>
                  <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                  >
                    {{ item.order_date }}
                  </td>
                  <td
                      aria-colindex="6"
                      role="cell"
                      class=""
                  >
                    {{ item.due_date }}
                  </td>
                  <td
                      aria-colindex="6"
                      role="cell"
                      class=""
                  >
                    <router-link class="btn btn-primary" :to="{ path: `/apps/invoice/preview/${item.id}`}">View</router-link>
                  </td>
                </tr>
                </tbody><!----></table>
            </div>
            <div data-v-5cb60a6f="" class="mx-2 mb-2">
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from '../invoiceStoreModule'
import { mapActions, mapGetters } from 'vuex/dist/vuex.esm.browser'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
  created() {
    this.getListInvoice({}).then(res => {
      console.log('RES getListInvoice', res)
    })
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['getterListInvoice']),
  },
  methods: {
    ...mapActions(['getListInvoice']),
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
